



























































































































import Vue from "vue";
import { Form, Field, Checkbox, CheckboxGroup, Button } from "vant";
export default Vue.extend({
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button
  },
  data() {
    return {
      type: this.$route.query.type || 0
    };
  }
});
